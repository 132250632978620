import React from "react";
import { Link, useParams } from "react-router-dom";
import ServiceData from "../data/service/ServiceData.json";
import Layout from "../common/Layout";
import { FiFile, FiMapPin, FiPhone, FiCheck } from "react-icons/fi";

const ServiceDetails = () => {
  const { id } = useParams();
  const serviceItem = ServiceData.find((item) => item.id === parseInt(id, 10));

  if (!serviceItem) {
    return <div>Service not found</div>;
  }

  return (
    <Layout>
      <div className="rwt-portfolio-details rn-section-gap">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <div className="inner">
                <div className="details-list">
                  <h2 className="title text-center mb--50">{serviceItem.detailstitle}</h2>
                  
                  <div className="row row--0 about-wrapper align-items-center">
                    <div className="col-lg-6">
                      <div className="thumbnail">
                        <img
                          className="w-100"
                          src={serviceItem.image}
                          alt={serviceItem.title}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 mt_md--30 mt_sm--30">
                      <div className="content">
                        <div className="inner">
                          <div className="details-list-description">
                            <p className="description">{serviceItem.description}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mt--60">
                    <div className="col-lg-6">
                      <div className="content-left">
                        <h4 className="title">Key Features</h4>
                        <ul className="list-style">
                          {serviceItem.keyFeatures.map((feature, index) => (
                            <li key={index}>
                              <FiCheck className="feather-check" /> {feature}
                            </li>
                          ))}
                        </ul>

                        <h4 className="title mt--50">Benefits</h4>
                        <ul className="list-style">
                          {serviceItem.benefits.map((benefit, index) => (
                            <li key={index}>
                              <FiCheck className="feather-check" /> {benefit}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="content-right">
                        <h4 className="title">Use Cases</h4>
                        <ul className="list-style">
                          {serviceItem.useCases.map((useCase, index) => (
                            <li key={index}>
                              <FiCheck className="feather-check" /> {useCase}
                            </li>
                          ))}
                        </ul>

                        <h4 className="title mt--50">Technologies</h4>
                        <ul className="list-style">
                          {serviceItem.technologies.map((tech, index) => (
                            <li key={index}>
                              <FiCheck className="feather-check" /> {tech}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="row mt--50">
                    <div className="col-lg-12">
                      <div className="contact-button text-center">
                        <Link className="btn-default btn-large" to="/contact">
                          Get Started Today
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ServiceDetails;
