import React, { useState, useEffect } from "react";
import BlogClassicData from '../../../data/blog/BlogList.json';
import BlogList from './BlogList';
import { FaSpinner } from "react-icons/fa";
import filters from "./Filters";

const alldataBLog = BlogClassicData;
const BlogProp = ({ column, StyleVarProp }) => {
    const [getAllItems] = useState(alldataBLog);
    const [visibleItems, setVisibleItems] = useState([]);

    useEffect(() => {
        setVisibleItems(getAllItems);
    }, [getAllItems]);

    return (
        <>
            {visibleItems.map((item) => (
                <div key={item.id} className={column}>
                    <BlogList StyleVar={StyleVarProp} data={item} />
                </div>
            ))}
        </>
    )
}
export default BlogProp;