import React from 'react';
import { Container } from 'react-bootstrap';

const Copyright = () => {
    return (
        <div className="copyright-area bg-dark text-light py-3">
            <Container>
                <div className="text-center">
                    <p className="mb-0">© {new Date().getFullYear()} NovoUp Technologies. All rights reserved.</p>
                </div>
            </Container>
        </div>
    );
};

export default Copyright;