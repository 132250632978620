import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";

const TimelineData = [
    {
        id: "1",
        date: "Phase 1",
        title: "Assessment & Strategy",
        description: "We begin with a comprehensive analysis of your current technology landscape and business objectives to create a tailored transformation roadmap.",
        image: "timeline-01",
        workingStep: [
            {
                stepTitle: "Technology Assessment",
                stepDescription: "Evaluate existing systems, infrastructure, and processes to identify opportunities and challenges.",
            },
            {
                stepTitle: "Strategic Planning",
                stepDescription: "Develop a detailed roadmap aligned with your business goals and industry best practices.",
            },
        ]
    },
    {
        id: "2",
        date: "Phase 2",
        title: "Implementation & Optimization",
        description: "Our expert team executes the transformation strategy while ensuring minimal disruption to your business operations.",
        image: "timeline-02",
        workingStep: [
            {
                stepTitle: "Agile Implementation",
                stepDescription: "Execute solutions using agile methodologies to ensure rapid delivery and continuous improvement.",
            },
            {
                stepTitle: "Performance Optimization",
                stepDescription: "Fine-tune systems and processes to maximize efficiency and ROI.",
            },
        ]
    },
    {
        id: "3",
        date: "Phase 3",
        title: "Support & Innovation",
        description: "We provide ongoing support and continuously introduce innovative solutions to keep you ahead of the competition.",
        image: "timeline-03",
        workingStep: [
            {
                stepTitle: "24/7 Enterprise Support",
                stepDescription: "Ensure seamless operations with our round-the-clock monitoring and support services.",
            },
            {
                stepTitle: "Innovation Pipeline",
                stepDescription: "Stay ahead with regular updates on emerging technologies and implementation recommendations.",
            },
        ]
    },
]

const TimelineOne = () => {
    return (
        <div className="rn-timeline-wrapper timeline-style-one position-relative">
            <div className="timeline-line"></div>
            {/* Start Single Timeline  */}

            {TimelineData.map((data, index) =>(
                <div className="single-timeline mt--50" key={index}>
                    <div className="timeline-dot">
                        <div className="time-line-circle"></div>
                    </div>
                    <div className="single-content">
                        <div className="inner">
                            <div className="row row--30 align-items-center">
                                <div className="order-2 order-lg-1 col-lg-6 mt_md--40 mt_sm--40">
                                    <div className="content">
                                        <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <span className="date-of-timeline">{data.date}</span>
                                        </ScrollAnimation>

                                        <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <h2 className="title">{data.title}</h2>
                                        </ScrollAnimation>

                                        <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <p className="description">{data.description}</p>
                                        </ScrollAnimation>

                                        <div className="row row--30">
                                            {data.workingStep.map((data, index)=>(
                                                <div className="col-lg-6 col-md-6 col-12" key={index}>
                                                    <div className="working-list">
                                                        <ScrollAnimation 
                                                        animateIn="fadeInUp"
                                                        animateOut="fadeInOut"
                                                        animateOnce={true}>
                                                            <h5 className="working-title">{data.stepTitle}</h5>
                                                        </ScrollAnimation>

                                                        <ScrollAnimation 
                                                        animateIn="fadeInUp"
                                                        animateOut="fadeInOut"
                                                        animateOnce={true}>
                                                            <p>{data.stepDescription}</p>
                                                        </ScrollAnimation>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="order-1 order-lg-2 col-lg-6">
                                    <div className="thumbnail">
                                        <img className="w-100" src={`./images/timeline/${data.image}.jpg`} alt="Corporate React Template" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
           
            {/* End Single Timeline  */}
        </div>
    )
}

export default TimelineOne;
