import React from 'react';
import {slugify} from "../../utils";
import {Link} from "react-router-dom";
import { FiUser, FiCalendar } from "react-icons/fi";

const BlogDetailsContent = ({ data }) => {
    return (
        <>
            <div className="post-page-banner rn-section-gapTop">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <div className="content text-center">
                                <div className="page-title">
                                    <h1 className="theme-gradient">{data.title}</h1>
                                </div>
                                <ul className="rn-meta-list">
                                    <li><FiUser /><Link to={process.env.PUBLIC_URL + `/archive/${slugify(data.author[0])}`}>{data.author[0]}</Link></li>
                                    <li><FiCalendar />{data.date}</li>
                                </ul>
                                <div className="thumbnail alignwide mt--60">
                                    <img 
                                        className="w-100 radius" 
                                        src={process.env.PUBLIC_URL + data.largeImage} 
                                        alt={data.title}
                                        style={{
                                            maxHeight: '600px',
                                            objectFit: 'cover'
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="blog-details-content pt--60 rn-section-gapBottom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <div className="content">
                                {data.body.map((value, i) => {
                                    return(
                                        <div key={i} dangerouslySetInnerHTML={{__html: value}} />
                                    )
                                })}
                            
                                <div className="category-meta">
                                    <span className="text">Tags:</span>
                                    {data.tags && data.tags.map((tag, index) => (
                                        <Link 
                                            key={index} 
                                            to={process.env.PUBLIC_URL + `/tag/${slugify(tag)}`}
                                            className="rn-tag"
                                        >
                                            {tag}
                                        </Link>
                                    ))}
                                </div>

                                <div className="call-to-action mt--60 text-center">
                                    <h3>Interested in implementing these solutions?</h3>
                                    <p className="mt--20">Discover how our products can help transform your business</p>
                                    <Link 
                                        to={process.env.PUBLIC_URL + "/products"} 
                                        className="btn-default btn-large mt--20"
                                    >
                                        Explore Our Solutions
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default BlogDetailsContent;
