import React from "react";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import ServiceData from "../../data/service/ServiceData.json";
import { FiArrowRight } from "react-icons/fi";

const HomeServices = ({ textAlign, serviceStyle }) => {
  // Filter AI-related services
  const aiServices = ServiceData.filter(service => 
    service.id === 6 || // Next-Gen AI Solutions
    service.id === 4 || // AI & Machine Learning Solutions
    service.id === 3    // Strategic Data Intelligence
  );

  return (
    <>
      <div className="row row--15 service-wrapper">
        {aiServices.map((val, i) => (
          <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={val.id}>
            <ScrollAnimation
              animateIn="fadeInUp"
              animateOut="fadeInOut"
              animateOnce={true}
            >
              <div className={`service ${serviceStyle} ${textAlign}`}>
                <div className="inner">
                  <div className="image">
                    <Link to={`/ServiceDetails/${val.id}`}>
                      <img src={`${val.image}`} alt="card Images" />
                    </Link>
                  </div>
                  <div className="content">
                    <h4 className="title">
                      <Link
                        to={`/ServiceDetails/${val.id}`}
                        dangerouslySetInnerHTML={{ __html: val.title }}
                      ></Link>
                    </h4>
                    <p
                      className="description b1 color-gray mb--0"
                      dangerouslySetInnerHTML={{ __html: val.shortdescription }}
                    ></p>
                  </div>
                </div>
              </div>
            </ScrollAnimation>
          </div>
        ))}
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="text-center mt--30">
            <Link
              className="btn-default btn-medium round btn-icon"
              to="/service"
            >
              View All Services
              <i className="icon">
                <FiArrowRight />
              </i>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeServices; 