import React from "react";
import Typed from "react-typed";
import SEO from "../common/SEO";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import HeaderTwo from "../common/header/HeaderTwo";
import FooterTwo from "../common/footer/FooterTwo";
import Copyright from "../common/footer/Copyright";
import AboutOne from "../elements/about/AboutOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";
import Mission from "../elements/about/Mission";
import BlogList from "../components/blog/itemProp/BlogList";
import BlogClassicData from "../data/blog/BlogList.json";
import HomeServices from "../elements/service/HomeServices";

var BlogListData = BlogClassicData.slice(0, 3);

const BusinessConsulting2 = () => {
  return (
    <>
      <SEO title="NovoUp - Enterprise Technology Solutions" />
      <main className="page-wrapper">
        <HeaderTwo btnStyle="round" HeaderSTyle="header-not-transparent" />

        {/* Start Slider Area  */}
        <div className="slider-area slider-style-1 bg-transparent height-850">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner text-center">
                  <span className="subtitle">ENTERPRISE TECHNOLOGY SOLUTIONS</span>
                  <h1 className="title theme-gradient display-two">
                    Transforming Enterprises<br />Through Technology
                  </h1>
                  <div className='theme-gradient display-two'>
                    <Typed
                      strings={[
                        "Cloud & Digital Transformation",
                        "AI & Machine Learning Solutions",
                        "Enterprise Security & Compliance",
                        "Data Analytics & Intelligence",
                        "IoT & Connected Systems",
                        "Custom Enterprise Software",
                        "DevOps & Automation"
                      ]}
                      typeSpeed={80}
                      backSpeed={5}
                      backDelay={1000}
                      loop
                    />
                  </div>
                  <div className="mt-4">
                    <p className="description">
                      NovoUp empowers enterprises with cutting-edge technology solutions 
                      that drive digital transformation. We combine deep industry expertise 
                      with innovative technology to help organizations optimize operations, 
                      enhance security, and achieve sustainable growth in the digital age.
                    </p>
                  </div>
                  <div className="button-group mt-4">
                    <Link
                      className="btn-default btn-medium round btn-icon"
                      to="/service"
                    >
                      Explore Solutions{" "}
                      <i className="icon">
                        <FiArrowRight />
                      </i>
                    </Link>
                    <Link
                      className="btn-default btn-medium btn-border round btn-icon"
                      to="/contact"
                    >
                      Schedule Consultation
                      <i className="icon">
                        <FiArrowRight />
                      </i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}

        <Separator />

        {/* Start Service Area  */}
        <div className="rn-service-area rn-section-gapTop">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Featured Solutions"
                  title="AI & Data Intelligence Solutions"
                  description="Transform your business with our cutting-edge AI and data solutions, empowering you to make smarter decisions and drive innovation."
                />
              </div>
            </div>
            <HomeServices 
              serviceStyle="service__style--2"
              textAlign="text-center"
            />
          </div>
        </div>
        {/* End Service Area  */}

        {/* Start About Area  */}
        <AboutOne />
        {/* End About Area  */}

        <Separator />
        {/* Start Mission Area   */}
        <Mission />
        {/* End Mission Area  */}

        <Separator />
        <div className="blog-area rn-section-gap bg-color-extra-02">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Latest Insights"
                  title="Technology Trends & Insights"
                  description="Stay informed about the latest developments in enterprise technology, digital transformation, and innovation"
                />
              </div>
            </div>
            <div className="row row--15">
              {BlogListData.map((item) => (
                <div key={item.id} className="col-lg-4 col-md-6 col-12 mt--30">
                  <BlogList StyleVar="box-card-style-default" data={item} />
                </div>
              ))}
            </div>
          </div>
        </div>
        <FooterTwo />
        <Copyright />
      </main>
    </>
  );
};
export default BusinessConsulting2;
