import React from 'react';
import { FiCloud, FiCpu, FiShield } from "react-icons/fi";
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
    {
        icon: <FiCloud />,
        title: 'Cloud & Digital Transformation',
        description: 'Modernize your enterprise with our comprehensive cloud solutions and digital transformation services. From migration to optimization, we ensure a seamless journey to the cloud.'
    },
    {
        icon: <FiCpu />,
        title: 'AI & Data Intelligence',
        description: 'Harness the power of artificial intelligence and data analytics to drive smarter decisions. Our solutions help you extract actionable insights from your data assets.'
    },
    {
        icon: <FiShield />,
        title: 'Enterprise Security',
        description: 'Protect your digital assets with our advanced cybersecurity solutions. We implement robust security measures to safeguard your enterprise from evolving threats.'
    },
]

const ServiceOne = ({textAlign, serviceStyle}) => {
    return (
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="icon">
                                {val.icon}
                            </div>
                            <div className="content">
                                <h4 className="title w-600"><Link to="/service" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceOne;