import React from 'react';
import { Helmet } from 'react-helmet-async';

const SEO = ({ title }) => {
    return (
        <Helmet>
            <title>{title} | NovoUp Technologies</title>
            <meta name="description" content="NovoUp Technologies delivers innovative digital solutions for both government and private clients. Our expertise in artificial intelligence empowers us to build custom AI applications with agility and precision." />
            <meta name="keywords" content="AI solutions, custom AI applications, government technology, Samvad.io, Geosync.io, ListMyProp, WeighIQ, Caliber" />
            <meta property="og:title" content={`${title} | NovoUp Technologies`} />
            <meta property="og:description" content="NovoUp Technologies delivers innovative digital solutions for both government and private clients." />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://novoup.com" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={`${title} | NovoUp Technologies`} />
            <meta name="twitter:description" content="NovoUp Technologies delivers innovative digital solutions for both government and private clients." />
        </Helmet>
    );
};

export default SEO;


