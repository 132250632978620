import React from "react";
import SEO from "../common/SEO";
import HeaderTwo from "../common/header/HeaderTwo";
import FooterTwo from "../common/footer/FooterTwo";
import AboutFour from "../elements/about/AboutFour";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import CounterUpFour from "../elements/counterup/CounterUpFour";
import Separator from "../elements/separator/Separator";
import TechTitans from "../elements/advancePricing/TechTitans";

const AboutUs = () => {
  return (
    <>
      <SEO title="About NovoUp Technologies - Innovative Enterprise Solutions" />
      <main className="page-wrapper">
        <HeaderTwo
          btnStyle="btn-small round btn-icon"
          HeaderSTyle="header-transparent"
        />

        {/* Start Hero Area */}
        <div
          className="slider-area slider-style-1 height-850 bg_image"
          data-black-overlay="7"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/about_us_image-12.png)`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner pt--80 text-center">
                  <div>
                    <h3 className="rn-sub-badge">
                      <span className="theme-gradient">
                        Transforming Enterprises Through Innovation
                      </span>
                    </h3>
                  </div>
                  <h1 className="title display-one">
                    Empowering Businesses with<br />Next-Generation Technology
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Hero Area */}

        {/* Start About Content Area */}
        <div className="service-area rn-section-gapTop">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="content text-center">
                  <SectionTitle
                    textAlign="text-center"
                    radiusRounded=""
                    subtitle="Our Story"
                    title="Pioneering Digital Excellence"
                  />
                  <p className="description-large mb--40">
                    Founded in 2022, NovoUp Technologies emerged with a clear vision: to revolutionize how businesses operate in the digital age. We combine deep industry expertise with cutting-edge technology to deliver transformative solutions that drive real business value.
                  </p>
                  <p className="description mb--40">
                    Our commitment goes beyond just providing technology - we're dedicated to understanding your business challenges, crafting tailored solutions, and ensuring sustainable growth. With offices in India and the United States, we serve a global clientele with innovative products and services that span artificial intelligence, cloud computing, IoT, and enterprise software solutions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End About Content Area */}

        <Separator />

        {/* Start Team Section */}
        <div className="rwt-pricingtable-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Leadership"
                  title="Meet Our Visionary Team"
                  description="Bringing together decades of experience in technology, innovation, and business transformation"
                />
              </div>
            </div>
            <div className="row mt--40">
              <div className="">
                <TechTitans />
              </div>
            </div>
          </div>
        </div>
        {/* End Team Section */}

        <Separator />

        {/* Start Vision Section */}
        <AboutFour image="./images/about/thumbnail.png" />
        {/* End Vision Section */}

        {/* Start Stats Section */}
        <div className="rwt-counterup-area pb--100">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Our Impact"
                  title="Driving Digital Success"
                  description="Making a measurable difference in enterprise technology adoption and innovation"
                />
              </div>
            </div>
            <div className="mt_dec--30">
              <CounterUpFour
                column="col-lg-3 col-md-6 col-sm-6 col-12"
                counterStyle="counter-style-4"
                textALign="text-center"
              />
            </div>
          </div>
        </div>
        {/* End Stats Section */}

        <FooterTwo />
      </main>
    </>
  );
};

export default AboutUs;
