export const products = [
    {
        id: 'listmyprop',
        title: "ListMyProp.com",
        description: "Empowering UAE's real estate businesses with seamless property listings. ListMyProp.com is the leading property listing management platform in the UAE, enabling real estate agents and companies to publish and manage their listings across multiple regional portals with a single click. Our platform integrates with major UAE property portals while preparing for expansion across the Middle East and beyond.",
        image: "/images/product/listmyprop.png",
        features: [
            "Integration with major UAE property portals including Bayut, Property Finder, and Dubizzle",
            "Automated listing synchronization with real-time updates across all platforms",
            "UAE market-specific property categorization and amenities",
            "Advanced lead tracking with UAE market insights",
            "Automated property verification aligned with UAE regulations",
            "Multi-language support (Arabic & English)",
            "Smart pricing recommendations based on UAE market data",
            "Integrated CRM with UAE-specific lead nurturing workflows",
            "Mobile-responsive property showcase with virtual tours",
            "Secure document management system for property documentation"
        ],
        technologies: ['Real Estate Tech', 'Multi-portal Integration', 'UAE Market Analytics', 'Cloud Infrastructure', 'Automated Syndication'],
        benefits: [
            "Save 70% time in listing management across UAE portals",
            "Increase property visibility by 5x in the UAE market",
            "Reduce manual data entry and synchronization errors",
            "Track performance across all UAE property platforms",
            "Generate more qualified leads from multiple channels"
        ],
        contact: {
            email: "contact@novoup.com",
            phone: "+91 98814 01775",
            support: "24/7 Customer Support"
        },
        price: "Customized pricing based on listing volume and portal integrations",
        marketFocus: {
            current: "UAE Real Estate Market",
            expansion: "Planned expansion to Saudi Arabia, Qatar, and other GCC countries",
            portals: [
                "Bayut",
                "Property Finder",
                "Dubizzle",
                "UAE Property",
                "Other regional portals"
            ]
        }
    },
    {
        id: 'weighiq',
        title: "WeighIQ",
        description: "An IoT-powered intelligent weighing solution designed for industrial applications. WeighIQ transforms traditional weighbridges into smart monitoring systems that capture both static and dynamic weights of heavy vehicles. Our platform helps businesses monitor material movement in real-time, prevent losses, optimize logistics, and ensure compliance with industry standards.",
        image: "/images/product/weighiq.png",
        features: [
            "High-precision static and dynamic weight capture",
            "Real-time monitoring dashboard with alerts",
            "Comprehensive material movement tracking",
            "Advanced loss prevention analytics",
            "Seamless API integrations for logistics systems",
            "Automated weight ticket generation",
            "Vehicle recognition system with RFID/camera integration",
            "Customizable reporting and analytics",
            "Mobile app for remote monitoring",
            "Compliance and audit trail management"
        ],
        technologies: ['IoT', 'Industrial Tech', 'Real-time Analytics', 'Edge Computing', 'Cloud Infrastructure'],
        benefits: [
            "Reduce weighing time by 60%",
            "Prevent material losses up to 15%",
            "Improve logistics efficiency",
            "Ensure regulatory compliance",
            "Real-time visibility into operations"
        ],
        contact: {
            email: "sales@weighiq.com",
            phone: "+91 XXXX XXXX",
            demo: "Request Demo",
            support: "24/7 Technical Support"
        },
        price: "Custom pricing based on scale capacity and features"
    },
    {
        id: 'caliber',
        title: "CALIBER",
        description: "A cutting-edge commission automation platform that revolutionizes sales incentive management. CALIBER empowers businesses with AI-driven predictive pricing, flexible commission structures, automated payouts, and comprehensive analytics. Our platform streamlines the entire commission lifecycle while ensuring accuracy and transparency.",
        image: "/images/product/caliber.png",
        features: [
            "AI-powered commission calculations and predictions",
            "Advanced predictive pricing analytics",
            "Customizable multi-level commission structures",
            "Automated payout processing with compliance checks",
            "Real-time sales performance insights",
            "Integration with major accounting systems",
            "Dispute resolution workflow",
            "Commission statement generation",
            "Historical performance tracking",
            "Compliance and audit management"
        ],
        technologies: ['AI/ML', 'FinTech', 'Sales Analytics', 'Cloud Computing', 'Blockchain'],
        benefits: [
            "Reduce commission processing time by 90%",
            "Eliminate calculation errors",
            "Improve sales team satisfaction",
            "Real-time performance visibility",
            "Ensure regulatory compliance"
        ],
        contact: {
            email: "sales@caliber.io",
            phone: "+91 XXXX XXXX",
            demo: "Book a Demo",
            support: "Priority Support"
        },
        price: "Customized pricing based on transaction volume"
    },
    {
        id: 'samvad',
        title: "Samvad.io",
        description: "An AI-powered virtual call center that transforms customer engagement. Samvad.io enables businesses to create intelligent conversation workflows, automate lead outreach, schedule appointments, and gain deep insights through call sentiment analysis. Our platform combines cutting-edge AI with human-like interactions to deliver exceptional customer experiences.",
        image: "/images/product/samvad.png",
        features: [
            "Advanced AI-driven conversation workflows",
            "Intelligent lead qualification and outreach",
            "Automated appointment scheduling system",
            "Real-time call sentiment analysis",
            "Seamless CRM & vector database integration",
            "Multi-language support with local accents",
            "Custom voice and personality design",
            "Call recording and quality monitoring",
            "Performance analytics dashboard",
            "Compliance and data security features"
        ],
        technologies: ['AI/ML', 'NLP', 'Customer Engagement', 'Voice Recognition', 'Cloud Infrastructure'],
        benefits: [
            "Reduce operational costs by 60%",
            "24/7 customer engagement",
            "Increase lead conversion rates",
            "Consistent customer experience",
            "Scalable call center operations"
        ],
        contact: {
            email: "sales@samvad.io",
            phone: "+91 XXXX XXXX",
            demo: "Try Demo",
            support: "24/7 Technical Assistance"
        },
        price: "Flexible pricing based on call volume and features"
    },
    {
        id: 'geosync',
        title: "Geosync.io",
        description: "A powerful geospatial data integration platform that seamlessly merges non-GIS data with GIS shape data. Geosync.io offers sophisticated visualizations and actionable insights for industries like real estate, infrastructure, and urban planning. Our platform transforms complex spatial data into clear, actionable intelligence.",
        image: "/images/product/geosync.png",
        features: [
            "Advanced GIS data integration capabilities",
            "Seamless non-GIS data merging",
            "Comprehensive spatial analysis tools",
            "Real-time tax & ownership record integration",
            "Interactive data visualization",
            "Custom layer management",
            "Automated data validation",
            "Real-time collaboration tools",
            "Mobile field data collection",
            "API access for custom integration"
        ],
        technologies: ['GIS', 'Data Integration', 'Spatial Analysis', 'Cloud Computing', 'Big Data'],
        benefits: [
            "Reduce data processing time by 80%",
            "Improve decision accuracy",
            "Enable real-time collaboration",
            "Streamline planning processes",
            "Enhanced data visualization"
        ],
        contact: {
            email: "sales@geosync.io",
            phone: "+91 XXXX XXXX",
            demo: "Request Demo",
            support: "Technical Support"
        },
        price: "Custom pricing based on data volume and features"
    }
]; 