import React from 'react';
import {slugify} from "../../../utils";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";

const BlogList = ({ data, StyleVar }) => {
    const cate = data.categories.map((value, i) => {
        return (
            <Link to={process.env.PUBLIC_URL + `/category/${slugify(value)}`} key={i}>{value}{i !== data.categories.length - 1 && ", "}</Link>
        )
    });

    return (
        <div className={`rn-card ${StyleVar}`}>
            <div className="inner">
                <div className="thumbnail">
                    <Link to={process.env.PUBLIC_URL + `/blog-details/${data.id}`} className="image">
                        <img 
                            src={process.env.PUBLIC_URL + data.image} 
                            alt={data.title}
                            style={{ 
                                width: '100%', 
                                height: '300px', 
                                objectFit: 'cover',
                                borderRadius: '10px'
                            }}
                        />
                    </Link>
                </div>
                <div className="content">
                    <div className="category-info">
                        <div className="category-list">
                            {cate}
                        </div>
                        <div className="meta">
                            <span className="date">{data.date}</span>
                        </div>
                    </div>
                    <h4 className="title">
                        <Link to={process.env.PUBLIC_URL + `/blog-details/${data.id}`}>
                            {data.title}
                        </Link>
                    </h4>
                    <p className="description">{data.excerpt}</p>
                    <div className="blog-footer">
                        <Link to={process.env.PUBLIC_URL + `/archive/${slugify(data.author[0])}`} className="author">
                            By {data.author[0]}
                        </Link>
                        {' '}
                        <Link to={process.env.PUBLIC_URL + `/blog-details/${data.id}`} className="read-more">
                            Read More
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

BlogList.propTypes = {
    data: PropTypes.object
};

export default BlogList;
