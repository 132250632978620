import React from 'react';
import { Link } from 'react-router-dom';
import { products } from '../data/products';
import HeaderTwo from '../common/header/HeaderTwo';
import FooterTwo from '../common/footer/FooterTwo';
import SEO from '../common/SEO';
import SectionTitle from '../elements/sectionTitle/SectionTitle';
import { FiArrowRight } from 'react-icons/fi';

const Products = () => {
    return (
        <>
            <SEO title="NovoUp Technologies - Our Products" />
            <HeaderTwo btnStyle="btn-small" />
            <div className="main-content">
                {/* Start Hero Section */}
                <div className="rwt-portfolio-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="Our Products"
                                    title="Innovative Solutions for Modern Businesses"
                                    description="At NovoUp Technologies, we are committed to developing innovative solutions that drive efficiency, enhance decision-making, and transform industries."
                                />
                            </div>
                        </div>
                        
                        {/* Start Product Grid */}
                        <div className="row row--25 mt--50 mt_md--30 mt_sm--30">
                            {products.map((product) => (
                                <div key={product.id} className="col-lg-4 col-md-6 col-12 mt--50 mt_md--30 mt_sm--30">
                                    <div className="rn-portfolio" data-toggle="modal" data-target={`#${product.id}`}>
                                        <div className="inner">
                                            <div className="thumbnail">
                                                <Link to={`/product/${product.id}`}>
                                                    <img 
                                                        src={process.env.PUBLIC_URL + product.image} 
                                                        alt={product.title}
                                                        style={{
                                                            width: '100%',
                                                            height: '300px',
                                                            objectFit: 'cover',
                                                            borderRadius: '10px'
                                                        }}
                                                    />
                                                </Link>
                                            </div>
                                            <div className="content">
                                                <div className="category-info">
                                                    <div className="category-list">
                                                        {product.technologies.map((tech, index) => (
                                                            <span key={index} className="badge bg-primary me-2">
                                                                {tech}
                                                            </span>
                                                        ))}
                                                    </div>
                                                </div>
                                                <h4 className="title mb--20">
                                                    <Link to={`/product/${product.id}`}>
                                                        {product.title}
                                                    </Link>
                                                </h4>
                                                <p className="description">
                                                    {product.description}
                                                </p>
                                                <div className="read-more-button mt--20">
                                                    <Link to={`/product/${product.id}`} className="btn-default btn-small">
                                                        Learn More <FiArrowRight />
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {/* End Product Grid */}
                        
                        {/* Start Contact Section */}
                        <div className="row mt--100 mt_md--60 mt_sm--60">
                            <div className="col-lg-12">
                                <div className="contact-info text-center">
                                    <h3>Ready to Transform Your Business?</h3>
                                    <p className="description mt--20">
                                        Contact us to learn more about how our products can elevate your business!
                                    </p>
                                    <div className="call-to-action mt--30">
                                        <Link to="/contact" className="btn-default btn-large">
                                            Get in Touch
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Contact Section */}
                    </div>
                </div>
            </div>
            <FooterTwo />
        </>
    );
};

export default Products; 