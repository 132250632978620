import React from "react";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../../elements/breadcrumb/BreadcrumbOne";
import BlogProp from "./itemProp/BlogProp";
import SectionTitle from "../../elements/sectionTitle/SectionTitle";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import ScrollAnimation from "react-animate-on-scroll";

const BlogGridView = () => {
  return (
    <>
      {/* <SEO title="Blog List || Doob - React Business  Template" /> */}
      <SEO title="NovoUp Blog - Latest Insights & Updates" />
      <Layout>
        {/* <BreadcrumbOne 
                    title="The Easiest Way To Create Website <br /> Quick Copy & Make site."
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Blog List"
                /> */}
        {/* <div className="main-content"> */}
        {/* Start Blog Area  */}
        {/* <div className="rn-blog-area rn-section-gap">
                        <div className="container">
                            <div className="row mt_dec--30">
                                <BlogProp column="col-lg-4 col-md-6 col-12 mt--30" StyleVarProp="box-card-style-default" />
                            </div>
                        </div>
                    </div> */}
        {/* End Blog Area  */}
        {/* </div> */}
        <div className="main-content">
          {/* Start Blog Area */}
          <div className="rn-blog-area rn-section-gap">
            <div className="container">
              <div className="row mb--40">
                <div className="col-lg-12">
                  <SectionTitle
                    textAlign="text-center"
                    radiusRounded=""
                    subtitle="Latest Articles"
                    title="Insights & Updates"
                    description="Discover the latest trends, innovations, and insights in AI, PropTech, and Industrial Solutions"
                  />
                </div>
              </div>
              <div className="row row--15">
                <BlogProp column="col-lg-4 col-md-6 col-12 mt--30" StyleVarProp="box-card-style-default" />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default BlogGridView;
