import React from 'react';
import VideoTwo from '../video/VideoTwo';
import { FiCheck } from "react-icons/fi";

const PopupData = [
    {
        id: "01",
        image: "./images/bg/bg-image-4.jpg",
        popupLink: [
            'https://youtu.be/Kz94DNytgGU?si=8EWBSlB-Z3lepvBP',
        ],
    }
]

const AboutFour = ({image}) => {
    return (
        <div className="about-area about-style-4 rn-section-gap">
            <div className="container">
                <div className="row row--40 align-items-center">
                    <div className="col-lg-6">
                        {PopupData.map((item) => (
                            <div className="video-btn" key={item.id}>
                                <VideoTwo imagename={`${image}`} galleryItem={item} />
                            </div>
                        ))}
                    </div>
                    <div className="col-lg-6 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="inner">
                                <h3 className="title">Our Vision & <strong>Values</strong></h3>
                                <ul className="feature-list">
                                    <li>
                                        <div className="icon">
                                            <FiCheck />
                                        </div>
                                        <div className="title-wrapper">
                                            <h4 className="title">Innovation at the Core</h4>
                                            <p className="text">We're committed to pushing the boundaries of what's possible in enterprise technology. Our solutions leverage cutting-edge AI, cloud computing, and IoT to create transformative business outcomes that drive real value.</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <FiCheck />
                                        </div>
                                        <div className="title-wrapper">
                                            <h4 className="title">Client-Centric Approach</h4>
                                            <p className="text">Your success is our priority. We work closely with each client to understand their unique challenges, develop tailored solutions, and ensure seamless implementation with ongoing support and optimization.</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <FiCheck />
                                        </div>
                                        <div className="title-wrapper">
                                            <h4 className="title">Global Impact</h4>
                                            <p className="text">With a presence in India and the US, we're positioned to serve clients worldwide. Our diverse team brings together global expertise with local insights to deliver solutions that work across markets and cultures.</p>
                                        </div>
                                    </li>
                                </ul>
                                <div className="about-btn mt--30">
                                    <a className="btn-default" href="https://novoup-assets.s3.ap-south-1.amazonaws.com/NovoUpPortfolio.pdf" target="_blank" rel="noopener noreferrer">
                                        Download Company Portfolio
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutFour
