import React from "react";
import SEO from "../common/SEO";
import Layout from "../common/Layout";
import BreadcrumbOne from "../elements/breadcrumb/BreadcrumbOne";

const TermsAndConditions = () => {
  return (
    <>
      <SEO title="Terms and Conditions" />
      <Layout>
        <div className="main-content">
          <BreadcrumbOne 
            title="Terms and Conditions"
            rootUrl="/"
            parentUrl="Home"
            currentUrl="Terms and Conditions"
          />

          {/* Start Terms and Conditions Area  */}
          <div className="rwt-terms-area rn-section-gap">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="terms-content">
                    <h2 className="title">Terms and Conditions</h2>
                    <p className="description">Last updated: {new Date().toLocaleDateString()}</p>

                    <div className="terms-section">
                      <h3>1. Introduction</h3>
                      <p>
                        Welcome to NovoUp Technologies. By accessing our website and using our services, 
                        you agree to be bound by these Terms and Conditions. Please read them carefully.
                      </p>
                    </div>

                    <div className="terms-section">
                      <h3>2. Payment Terms</h3>
                      <p>
                        All payments for our services are processed through secure payment gateways including 
                        but not limited to Razorpay and Cashfree. By making a payment, you agree to:
                      </p>
                      <ul>
                        <li>Provide accurate and complete payment information</li>
                        <li>Authorize us to charge the specified amount to your payment method</li>
                        <li>Understand that all payments are non-refundable unless otherwise specified</li>
                        <li>Comply with the payment processor's terms of service</li>
                      </ul>
                    </div>

                    <div className="terms-section">
                      <h3>3. Payment Processing</h3>
                      <p>
                        We use third-party payment processors to handle payments. These processors have their 
                        own privacy policies and terms of service. By using our services, you agree to:
                      </p>
                      <ul>
                        <li>Allow payment processors to collect and process your payment information</li>
                        <li>Comply with all applicable payment processing regulations</li>
                        <li>Understand that payment processing fees may apply</li>
                        <li>Accept the payment processor's security measures and protocols</li>
                      </ul>
                    </div>

                    <div className="terms-section">
                      <h3>4. Refund Policy</h3>
                      <p>
                        Refunds are subject to our refund policy and applicable laws. We reserve the right 
                        to refuse refunds in cases of:
                      </p>
                      <ul>
                        <li>Services already delivered or completed</li>
                        <li>Violation of our terms of service</li>
                        <li>Fraudulent transactions</li>
                        <li>Services customized or modified according to client specifications</li>
                      </ul>
                    </div>

                    <div className="terms-section">
                      <h3>5. Data Protection</h3>
                      <p>
                        We take your data protection seriously. When processing payments, we:
                      </p>
                      <ul>
                        <li>Use secure, encrypted connections for all transactions</li>
                        <li>Never store complete payment information on our servers</li>
                        <li>Comply with applicable data protection laws</li>
                        <li>Share payment information only with authorized payment processors</li>
                      </ul>
                    </div>

                    <div className="terms-section">
                      <h3>6. Dispute Resolution</h3>
                      <p>
                        In case of payment disputes:
                      </p>
                      <ul>
                        <li>Contact us immediately with transaction details</li>
                        <li>Provide all relevant documentation</li>
                        <li>Cooperate with our investigation process</li>
                        <li>Follow the payment processor's dispute resolution procedures</li>
                      </ul>
                    </div>

                    <div className="terms-section">
                      <h3>7. Changes to Terms</h3>
                      <p>
                        We reserve the right to modify these terms at any time. Changes will be effective 
                        immediately upon posting to our website. Continued use of our services after changes 
                        constitutes acceptance of the new terms.
                      </p>
                    </div>

                    <div className="terms-section">
                      <h3>8. Contact Information</h3>
                      <p>
                        For questions about these terms or payment processing, please contact us at:
                      </p>
                      <ul>
                        <li>Email: contact@novoup.com</li>
                        <li>Phone: +91 98814 01775</li>
                        <li>Address: First Floor, Sailee Apartments, Caranzalem, Panjim, Goa 403 002</li>
                      </ul>
                    </div>

                    <div className="terms-section">
                      <h3>9. Governing Law</h3>
                      <p>
                        These terms are governed by the laws of India. Any disputes shall be subject to 
                        the exclusive jurisdiction of the courts in Goa, India.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Terms and Conditions Area  */}
        </div>
      </Layout>
    </>
  );
};

export default TermsAndConditions; 