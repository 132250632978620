import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { products } from '../../data/products';
import HeaderTwo from '../../common/header/HeaderTwo';
import FooterTwo from '../../common/footer/FooterTwo';
import SEO from '../../common/SEO';
import { FiCheck, FiHeadphones, FiMail, FiMapPin, FiArrowLeft, FiAward, FiDollarSign, FiGlobe, FiTarget } from 'react-icons/fi';
import { FaWhatsapp } from 'react-icons/fa';

const ProductDetail = () => {
    const { id } = useParams();
    const product = products.find(p => p.id === id);

    if (!product) {
        return <div>Product not found</div>;
    }

    return (
        <>
            <SEO title={`${product.title} - NovoUp Technologies`} />
            <HeaderTwo btnStyle="btn-small" />
            <div className="main-content">
                <div className="rwt-portfolio-details rn-section-gap">
                    <div className="container">
                        {/* Back Button */}
                        <div className="row mb--40">
                            <div className="col-lg-12">
                                <Link to="/products" className="btn-default btn-small">
                                    <FiArrowLeft /> Back to Products
                                </Link>
                            </div>
                        </div>

                        {/* Product Header */}
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--50">
                                    <h2 className="title">{product.title}</h2>
                                    <p className="description">{product.description}</p>
                                </div>
                            </div>
                        </div>

                        {/* Product Content */}
                        <div className="row row--25">
                            {/* Product Image */}
                            <div className="col-lg-6">
                                <div className="product-image mb--30">
                                    <img 
                                        src={process.env.PUBLIC_URL + product.image} 
                                        alt={product.title}
                                        style={{
                                            width: '100%',
                                            height: 'auto',
                                            borderRadius: '10px',
                                            boxShadow: '0 5px 15px rgba(0,0,0,0.1)'
                                        }}
                                    />
                                </div>

                                {/* Benefits Section */}
                                <div className="benefits mb--30">
                                    <h4 className="title-h4">Key Benefits</h4>
                                    <ul className="feature-list">
                                        {product.benefits.map((benefit, index) => (
                                            <li key={index} className="benefit-item">
                                                <FiAward className="benefit-icon" /> {benefit}
                                            </li>
                                        ))}
                                    </ul>
                                </div>

                                {/* Market Focus - Only for ListMyProp */}
                                {product.id === 'listmyprop' && product.marketFocus && (
                                    <div className="market-focus mb--30">
                                        <h4 className="title-h4">Market Focus</h4>
                                        <div className="market-info">
                                            <div className="current-market mb--20">
                                                <FiTarget className="market-icon" />
                                                <h5>Current Market</h5>
                                                <p>{product.marketFocus.current}</p>
                                            </div>
                                            <div className="expansion-plans mb--20">
                                                <FiGlobe className="market-icon" />
                                                <h5>Expansion Plans</h5>
                                                <p>{product.marketFocus.expansion}</p>
                                            </div>
                                            <div className="portal-integrations">
                                                <h5>Portal Integrations</h5>
                                                <div className="portal-tags">
                                                    {product.marketFocus.portals.map((portal, index) => (
                                                        <span key={index} className="badge bg-secondary me-2 mb-2">
                                                            {portal}
                                                        </span>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {/* Pricing Information */}
                                <div className="pricing mb--30">
                                    <h4 className="title-h4">Pricing</h4>
                                    <p className="price-info">
                                        <FiDollarSign /> {product.price}
                                    </p>
                                </div>
                            </div>

                            {/* Product Details */}
                            <div className="col-lg-6">
                                <div className="product-details">
                                    {/* Technologies */}
                                    <div className="technologies mb--30">
                                        <h4 className="title-h4">Technologies</h4>
                                        <div className="technology-tags">
                                            {product.technologies.map((tech, index) => (
                                                <span key={index} className="badge bg-primary me-2 mb-2">
                                                    {tech}
                                                </span>
                                            ))}
                                        </div>
                                    </div>

                                    {/* Features */}
                                    <div className="features mb--30">
                                        <h4 className="title-h4">Key Features</h4>
                                        <ul className="feature-list">
                                            {product.features.map((feature, index) => (
                                                <li key={index}>
                                                    <FiCheck /> {feature}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>

                                    {/* Contact Information */}
                                    <div className="contact-info mb--30">
                                        <h4 className="title-h4">Get in Touch</h4>
                                        <p>Interested in learning more about {product.title}? Contact our team for a personalized demo.</p>
                                        
                                        <ul className="contact-list">
                                            <li>
                                                <FiHeadphones />
                                                <a href="tel:+91 9881401775">+91 98814 01775</a>
                                                <span className="separator">|</span>
                                                <a href="tel:+91 9028064100">+91 90280 64100</a>
                                                <span className="separator">|</span>
                                                <a href="tel:+1 6122476042">+1 612 247 6042</a>
                                            </li>
                                            <li>
                                                <FiMail />
                                                <a href="mailto:contact@novoup.com">contact@novoup.com</a>
                                            </li>
                                            <li>
                                                <FiMapPin />
                                                <span>India Office: First Floor, Sailee Apartments, Caranzalem, Panjim, Goa 403 002</span>
                                            </li>
                                            <li>
                                                <FiMapPin />
                                                <span>US Office: Prosper, Texas</span>
                                            </li>
                                        </ul>
                                    </div>

                                    {/* Call to Action */}
                                    <div className="call-to-action">
                                        <a href="mailto:contact@novoup.com" className="btn-default btn-large">
                                            Request a Demo
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterTwo />
        </>
    );
};

export default ProductDetail; 