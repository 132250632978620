import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { FaWhatsapp } from 'react-icons/fa';

const FooterTwo = () => {
    return (
        <footer className="footer-style-default">
            <div className="footer-top">
                <Container>
                    <Row>
                        <Col md={4}>
                            <div className="rn-footer-widget">
                                <h4 className="title">NovoUp Technologies</h4>
                                <p className="description">
                                    Delivering innovative digital solutions for both government 
                                    and private clients with expertise in artificial intelligence.
                                </p>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="rn-footer-widget">
                                <h4 className="title">Products</h4>
                                <ul className="footer-link">
                                    <li><Link to="/product/samvad">Samvad.io</Link></li>
                                    <li><Link to="/product/geosync">Geosync.io</Link></li>
                                    <li><Link to="/product/listmyprop">ListMyProp</Link></li>
                                    <li><Link to="/product/weighiq">WeighIQ</Link></li>
                                    <li><Link to="/product/caliber">Caliber</Link></li>
                                </ul>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="rn-footer-widget">
                                <h4 className="title">Contact</h4>
                                <ul className="footer-link">
                                    <li style={{ marginBottom: '15px' }}>
                                        <a href="mailto:contact@novoup.com">
                                            Email: contact@novoup.com
                                        </a>
                                    </li>
                                    <li style={{ marginBottom: '10px' }}>
                                        <a href="tel:+919881401775">
                                            +91 98814 01775
                                        </a>
                                    </li>
                                    <li style={{ marginBottom: '10px' }}>
                                        <a href="tel:+919028064100">
                                            +91 90280 64100
                                        </a>
                                    </li>
                                    <li style={{ marginBottom: '20px' }}>
                                        <a href="tel:+16122476042">
                                            +1 612 247 6042
                                        </a>
                                    </li>
                                    <li style={{ marginBottom: '15px' }}>
                                        <strong>India Office:</strong><br />
                                        First Floor, Sailee Apartments,<br />
                                        Caranzalem, Panjim,<br />
                                        Goa 403 002
                                    </li>
                                    <li>
                                        <strong>US Office:</strong><br />
                                        Prosper, Texas
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="footer-bottom">
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className="footer-bottom-content">
                                <p className="copyright-text">
                                    © {new Date().getFullYear()} NovoUp Technologies. All rights reserved.
                                </p>
                                <div className="footer-bottom-links">
                                    <Link to="/terms-and-conditions">Terms & Conditions</Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </footer>
    );
};

export default FooterTwo;
